import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom'; // Assuming you are using react-router-dom for routing

import logo from './hypen-logo.png';
import verified from './verified.png';
import notverified from './notverified.png';

import './App.css';
import { Card, TextField, Button } from '@mui/material';
import data from './data.json';

function App() {
  const location = useLocation();
  const [verificationStatus, setVerificationStatus] = useState('pending');
  const [seletedInvoice, setSelectedInvoice] = useState(null);
  useEffect(() => {
    // Function to get URL parameters
    const getURLParams = (search) => {
      const params = new URLSearchParams(search);
      return {
        code: params.get('code'),
        invoiceId: params.get('invoiceId'),
      };
    };

    const { code } = getURLParams(location.search);

    // Check if the verification code and invoice ID are present
    if (code ) {
      // Find the invoice with matching ID and secret key
      const matchingInvoice = data.find(
        (invoice) =>
          invoice.invoiceSecretKey === code
      );

      if (matchingInvoice) {
        // Invoice found, verification successful
        setVerificationStatus('verified');
        setSelectedInvoice(matchingInvoice);
      } else {
        // Invoice not found, verification failed
        setVerificationStatus('not-verified');
      }
    }
    console.log("search")
  }, [location.search]);

  const [key, setKey] = useState('');


  const verifyInvoice = () => {
    // Find the invoice with matching ID and secret key
    console.log(key)
    const matchingInvoice = data.find(
      (invoice) =>
        invoice.invoiceSecretKey === key
    );

    if (matchingInvoice) {
      // Invoice found, verification successful
      setVerificationStatus('verified');
      setSelectedInvoice(matchingInvoice);
    } else {

      // Invoice not found, verification failed
      setVerificationStatus('not-verified');
    }
  };
  
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="hypen-logo" alt="logo" />
        <Card style={{
          paddingLeft: 20,
          paddingRight: 20,
          paddingBottom: 20,
          borderRadius: 10,
          marginLeft:20,
          marginRight:20,
        }}>
          {verificationStatus === 'verified' ? (
            <div>
               <br></br>
              {/* <TextField
                id="outlined-basic"
                label="Secret Key"
                variant="outlined"
                size="small"
                onChange={(e) => setKey(e.target.value)}
              />                */}
           
              Hi {seletedInvoice?.invoiceClient?.clientName}, Your quotation/invoice is verified by Hypen <br></br>
              <br></br>

              <img style={{height:200}} src={verified} alt="Verified" />
            </div>
          ) : verificationStatus === 'not-verified' ? (
            <div>
              {/* <TextField
                id="outlined-basic"
                label="Secret Key"
                variant="outlined"
                size="small"
                onChange={(e) => setKey(e.target.value)}
              /> */}
             
              <br></br>
              Hi, Your quotation/invoice is not valid. <br></br>
              <br></br>
              <img style={{height:200}}  src={notverified} alt="Not Verified" />
            </div>
          ) : (
            <div>
              <br></br>
              Hi, this is Hypen Verification Portal. <br></br>
              Please enter your invoice key to verify your invoice. <br></br>
              or scan the qr code in your invoice to verify. <br></br>
            
              <br></br>
              <TextField
                id="outlined-basic"
                label="Invoice Key"
                variant="outlined"
                size="small"
                onChange={(e) => setKey(e.target.value)}
              />
              <br></br>
              <Button
                variant="contained"
                style={{ marginTop: 10 }}
                onClick={verifyInvoice}
              >
                Verify
              </Button>
            </div>
          )}
        </Card>
       <span style={{marginTop:10,fontWeight:'600',fontSize:16,color:'black'}}>
        Powered by Hypen Business Suit.
        </span>
      </header>
    </div>
  );
}

export default App;
